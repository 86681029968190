import { useContext, useEffect, useMemo } from "react";
import {
  CasticulateNode,
  SlideContext,
} from "../../../pages/components/Casticulate";
import { MustacheTemplateRenderer } from "../../../atoms/components";
import camelcaseKeys from "camelcase-keys";
import { getExternalLinkTemplate } from "./template/externalLinkTemplate";
import { useSearchParams } from "react-router-dom";

export interface CasticulateExternalLinkProps {
  node?: CasticulateNode;
  cookRecipes?: {
    cookAsCompletedInsideCourse: () => void;
    cookAttemptInsideCourse: () => void;
    cookAsCompletedOutsideCourse: () => void;
    cookAttemptOutsideCourse: () => void;
  };
}

export function CasticulateExternalLink({
  node,
  cookRecipes,
}: CasticulateExternalLinkProps) {
  const slideContext = useContext(SlideContext);
  const nodeData = camelcaseKeys(node?.data, { deep: true });
  const [searchParams] = useSearchParams();
  const courseBlockUuid = searchParams.get("course_block_uuid");
  const courseUuid = searchParams.get("course_uuid");
  const resourceUuid = searchParams.get("resource_uuid");
  const courseScheduleUuid = searchParams.get("course_schedule_uuid");
  const referrerInfo = `${
    courseBlockUuid ? "&course_block_uuid=" + courseBlockUuid : ""
  }${courseUuid ? "&course_uuid=" + courseUuid : ""}${
    resourceUuid ? "&resource_uuid=" + resourceUuid : ""
  }${courseScheduleUuid ? "&course_schedule_uuid=" + courseScheduleUuid : ""}`;

  const isInCourse = !!searchParams.get("course_uuid");

  const EXTERNAL_LINK_TEMPLATE_VIEW = useMemo(
    () => ({
      isGbf: /gbf/g.test(window.location.href),
      header: nodeData.header || "Download Link",
      subtext: nodeData.subtext || nodeData.subText || "",
      downloadText: nodeData.downloadText || "Go To External Link",
      link: `/api/externallink-casticulate?uri=${encodeURIComponent(
        nodeData.uri
      )}${referrerInfo}`,
      imageUri: nodeData.imageUri,
      // isHidden: node?.slideType === "START" ? "hidden" : "flex",
      progressBar: slideContext.progressBar,
      lastVisitedTitlePage: function () {
        return function (val: any, render: any) {
          const id = render(val);
          const progressBarItemTitle = slideContext.progressBar.find(
            (progressBarItem) =>
              progressBarItem.id === slideContext.lastVisitedTitlePageId
          )?.data.title;
          return id === slideContext.lastVisitedTitlePageId &&
            (progressBarItemTitle ? progressBarItemTitle.trim() !== "" : false)
            ? "pr-4"
            : "hidden";
        };
      },
      lastItemInProgressBar: function () {
        return function (val: any, render: any) {
          const id = render(val);
          return id ===
            slideContext.progressBar[slideContext.progressBar.length - 1].id
            ? "hidden"
            : "";
        };
      },
    }),
    [slideContext.progressBar, slideContext.lastVisitedTitlePageId, nodeData]
  );

  // useEffect(() => {
  //   console.log("ran");
  //   if (cookRecipes)
  //     isInCourse
  //       ? cookRecipes?.cookAsCompletedInsideCourse()
  //       : cookRecipes?.cookAsCompletedOutsideCourse();
  // }, []);

  return (
    <div className="box-border flex flex-col items-center h-full mx-auto">
      <MustacheTemplateRenderer
        template={getExternalLinkTemplate(nodeData.theme)}
        view={EXTERNAL_LINK_TEMPLATE_VIEW}
      />
    </div>
  );
}
